// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://yli.shadoit.co.uk/romeo/ws/api/',
  // apiUrl: 'https://yli.shadoit.co.uk/romeo/ws/api/',
  // apiUrl: 'https://callupro.open-dev.it/romeo/ws/api/',
  // apiUrl: 'http://localhost:6015/api/',
  categoryTypes: [
    'Mangia e bevi',
    'Trasporti',
    'Benessere',
    'Sport',
    'Storia e territorio',
    'Eventi',
    'Altro',
  ],
  languages: [
    { abr: 'it', language: 'Italiano' },
    { abr: 'es', language: 'Spagnolo' },
    { abr: 'en', language: 'Inglese' },
    { abr: 'de', language: 'Tedesco' },
    { abr: 'fr', language: 'Francese' },
    { abr: 'ru', language: 'Russo' },
    { abr: 'pt', language: 'Portoghese' },
  ],
  errorsMessages: [
    { errorCode: 400, message: 'Errore nella richiesta' },
    { errorCode: 401, message: 'Non autorizzato' },
    { errorCode: 403, message: 'User o password errati' },
    { errorCode: 404, message: 'Risorsa richiesta non trovata' },
    { errorCode: 408, message: 'Richiesta scaduta' },
    { errorCode: 500, message: 'Errore da parte del server' },
  ],
  languagesTranslate: ['en', 'it'],
  defaultLanguage: 'en',
  langid: ['USA', 'ITA'],
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
